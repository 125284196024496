import * as React from "react"
import { Layout } from "../components/layout"
import { Helmet } from 'react-helmet'

const PrivacyPage = () => {
  return (
    <Layout>
      <Helmet defer={false}>
        <title>Privacy Policy</title>
        <meta name="description" content="ianknowles.com privacy policy and information." />
      </Helmet>
      <main>
        <section className="breath content-section">
          <div className="container">
            <h1>Privacy Policy</h1>
            <p>
              This privacy policy (<strong>&quot;Policy&quot;</strong>) applies to personal data that we collect from you as a customer (<strong>&quot;you&quot;</strong> or
              <strong>&quot;your&quot;</strong>). It provides information on what data we collect, why we collect the data, how it is used and the lawful basis
              on which your personal data is processed, and what your rights are under the applicable data protection and
              privacy laws, including the General Data Protection Regulation (<strong>&quot;GDPR&quot;</strong>).
            </p>
            <h4>1. WHO ARE WE</h4>
            <p>
              We are IanKnowles.com and are the controller responsible for your personal data.
            </p>
            <h4>2. WHAT DO WE COLLECT</h4>
            <p>
              We collect the following information from you:
            </p>

            <ul>
              <li>
                Personal information: This includes your name, address, e-mail address; phone number; country.
              </li>
              <li>
                Payment Information: Information about your debit/credit card and bank account information provided by
                you to our payment service providers, that we require for the purpose of processing payment for our goods
                and services.
              </li>
              <li>
                Other Information: Personal details you choose to give when corresponding with us by phone or e-mail or any other form of communication.
              </li>
            </ul>

            <h4>3. HOW WE USE YOUR PERSONAL INFORMATION</h4>
            <p>
              We use your personal information in the following ways:
            </p>
            <ul>
              <li>
                to provide you with our services and to create and deliver the products you have requested and contact you
                regarding your use of the services. Such use is necessary to respond to or implement your request and for the
                performance of the contract between you and us.
              </li>
              <li>
                As necessary for certain legitimate business interests, which include the following:
                <ul>
                  <li>
                    where we are asked to deal with any enquiries or complaints you make;
                  </li>
                  <li>
                    if you ask us to delete your data or to be removed from our marketing lists and we are required to fulfil
                    your request, to keep basic data to identify you and prevent further unwanted processing;
                  </li>
                  <li>
                    and to (a) comply with legal obligations, (b) respond to requests from competent authorities; (b) protect our
                    operations; (c) protect our rights, safety or property, and/or that of our affiliated businesses, you or
                    others; and (d) enforce or defend legal rights, or prevent damage.
                  </li>
                </ul>
              </li>
              <li>
                We may provide you with information about goods or services, events and other promotions we feel may
                interest you. We will contact you by email only with your consent, if this was given at the time you provided us
                with the personal data.
              </li>
              <li>
                We may use your personal data for other purposes which you have consented to at the time of providing your
                data.
              </li>
            </ul>
            <p>
              As used in this Privacy Policy, &quot;legitimate interests&quot; means our interests in conducting and managing our business.
              When we process your personal data for our legitimate interests, we make sure to consider and balance any
              potential impact on you, and your rights under data protection laws. Our legitimate interests do not automatically
              override your interests. We will not use your personal data for activities where our interests are overridden by the
              impact on you, unless we have your consent or those activities are otherwise required or permitted to by law. You
              have the right to object at any time to processing of your personal data that is based on our legitimate interests, on
              grounds relating to your particular situation (for more information on your rights, please see &quot;Your Data Protection
              Rights&quot; section below).
            </p>

            <h4>4. DISCLOSURE OF YOUR INFORMATION</h4>

            <p>
              We share your personal data with third parties in the following situations:
            </p>

            <ul>
              <li>
                Service Providers: we sometimes engage selected third parties who act on our behalf to support our
                operations, such as (i) card processing or payment services (see the section below headed &quot;Payment
                Information&quot;), (ii) IT suppliers and contractors (e.g. data hosting providers or delivery partners) as necessary to
                provide IT support and enable us to provide our goods/services, and (iii) providers of specialist services, these parties may
                access, process or store your personal data in the course of performing their duties to us and solely in order to
                perform the services we have hired them to provide.
              </li>
              <li>
                Business Transfers: if we sell our business or our company assets are acquired by a third party personal data
                held by us about our customers may be one of the transferred assets.
              </li>
              <li>
                Administrative and Legal Reasons: if we need to disclose your personal data (i) to comply with a legal
                obligation and/or judicial or regulatory proceedings, a court order or other legal process. (ii) to enforce our
                Terms &amp; Conditions or other applicable contract terms that you are subject to; (iii) to protect us, our members
                or contractors against loss or damage. This may include (without limit) exchanging information with the police,
                courts or law enforcement organisations.
              </li>
            </ul>

            <h4>5. PAYMENT INFORMATION</h4>

            <p>
              Any credit/debit card payments and other payments you make will be processed by our third party payment
              providers and the payment data you submit will be securely stored and encrypted by our payment service providers
              using up to date industry standards. Please note that we do not ourselves directly process or store the debit/credit
              card data that you submit.
            </p>
            <p>
              We store and use this card or payment information for the purpose of processing any future payments that you
              make for additional goods and services. We will store this data in accordance with our legal obligations under
              applicable law and only for so long as legally permitted.
            </p>
            <p>
              You may choose to opt out of us holding your card or payment data although this means that you will need to re-
              supply us with card/payment details for the purpose of making any future purchases.
            </p>

            <h4>6. DATA TRANSFERS</h4>

            <p>
              Your personal data will be transferred to and stored in countries other than the country in which the information was
              originally collected, including the United States and other destinations outside the European Economic Area
              (&quot;EEA&quot;) to our service providers for the purposes described above.
            </p>
            <p>
              Please note that the countries concerned may not provide the same legal standards for protection of your personal
              data that you have in the United Kingdom or EEA. Where we transfer your personal data to countries outside of the
              EEA we will take all steps to ensure that your personal data continue to be protected. We will implement
              appropriate safeguards for the transfer of personal data to our service providers in accordance with the applicable
              law, such as relying on our service providers’ Privacy Shield certification or implementing standard contractual
              clauses for data transfers. If you would like to receive more information on the safeguards that we implement,
              including copies of relevant data transfer contracts, please contact us as indicated below.
            </p>

            <h4>7. DATA RETENTION</h4>
            <p>
              We will keep your personal data only for as long as is reasonably necessary for the purposes outlined in this
              Privacy Policy, or for the duration required by any legal, regulatory, accounting or reporting requirements,
              whichever is the longer. When you consent to receive marketing communications, we will keep your data until you unsubscribe. Upon expiry of the applicable retention period we will securely destroy
              your personal data in accordance with applicable laws and regulations.
            </p>

            <h4>8. YOUR DATA PROTECTION RIGHTS</h4>
            <p>
              Certain applicable data protection laws give you specific rights in relation to your personal data. In particular, if the
              processing of your personal data is subject to the GDPR, you have the following rights in relation to your personal
              data:
            </p>
            <ul>
              <li>
                Right of access: If you ask us, we will confirm whether we are processing your personal data and, if so,
                provide you with a copy of that personal data along with certain other details. If you require additional copies,
                we may need to charge a reasonable fee.
              </li>
              <li>
                Right to rectification: If your personal data is inaccurate or incomplete, you are entitled to ask that we correct
                or complete it. If we shared your personal data with others, we will tell them about the correction where
                possible. If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your
                personal data so you can contact them directly.
              </li>
              <li>
                Right to erasure: You may ask us to delete or remove your personal data, such as where our legal basis for
                the processing is your consent and you withdraw consent. If we shared your data with others, we will tell them
                about the erasure where possible. If you ask us, and where possible and lawful to do so, we will also tell you
                with whom we shared your personal data with so you can contact them directly. We may continue processing
                personal data where this is necessary for a legitimate interest in doing so, as described in this Privacy Policy.
              </li>
              <li>
                Right to restrict processing: You may ask us to restrict or ‘block’ the processing of your personal data in
                certain circumstances, such as where you contest the accuracy of the data or object to us processing it. We will
                tell you before we lift any restriction on processing. If we shared your personal data with others, we will tell
                them about the restriction where possible. If you ask us, and where possible and lawful to do so, we will also
                tell you with whom we shared your personal data so you can contact them directly.
              </li>
              <li>
                Right to data portability: You have the right to obtain your personal data from us that you consented to give
                us or that was provided to us as necessary in connection with our contract with you. We will give you your
                personal data in a structured, commonly used and machine-readable format. You may reuse it elsewhere.
              </li>
              <li>
                Right to object: You may ask us at any time to stop processing your personal data, and we will do so:
                If we are relying on a legitimate interest to process your personal data -- unless we demonstrate compelling
                legitimate grounds for the processing or
                If we are processing your personal data for direct marketing.
              </li>
              <li>
                Right to withdraw consent: If we rely on your consent to process your personal data, you have the right to
                withdraw that consent at any time. This will not affect the lawfulness of processing of your data before we
                received notice that you wished to withdraw your consent.
              </li>
              <li>
                Right to lodge a complaint with the data protection authority: If you have a concern about our privacy
                practices, including the way we handled your personal data, you can report it to the UK data protection
                authority (the Information Commissioner’s Office or ICO), or, as the case may be, any other competent data
                protection authority of an EU member state that is authorised to hear those concerns (you may find EU Data
                Protection Authorities’ contact information here).
                If you wish to exercise any of these rights please contact us as described in the &quot;Contact&quot; section below. We may
                also need to ask you for further information to verify your identity before we can respond to any request.
              </li>
            </ul>

            <h4>9. CONTACT</h4>
            <p>
                Questions, comments or requests regarding this Privacy Policy should be queried on our contact form <a href="/contact">here</a>
            </p>
          </div>
      </section>
      </main>
    </Layout>
  )
}
export default PrivacyPage